@use "./vars";
@use "./fonts";
@use "./editor";

/*
.el-message-box {
  max-width: 90vw;
}

.el-alert__description {
  line-height: 1.4;
}
.el-button {
  font-family: stja, system-ui, sans-serif;
}

.el-form--label-top .el-form-item__label {
  line-height: 1;
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before {
  display: none;
}
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:after {
  content: "*";
  color: #c41440;
  margin-left: 4px;
}

.el-form--inline .el-radio-group .el-radio {
  margin-right: 10px;
}
.el-form--inline .el-radio-group .el-radio__label {
  padding-left: 5px;
}

.el-dialog__body.el-dialog__body {
  word-break: normal;
}

.el-radio.is-bordered.is-bordered {
  margin-right: 0;
}

.el-dialog__body + .el-dialog__footer {
  padding-top: 0;
}


.el-tag > .el-tag {
  margin-top: -1px;
  margin-bottom: -1px;
}
.el-tag--mini > .el-tag:first-child {
  margin-left: -6px;
}
.el-tag--mini > .el-tag:last-child {
  margin-right: -6px;
}


.el-tabs__header.is-left .el-tabs__item {
  padding: 0 10px 0 0;
}

*/

.el-empty__description p {
  line-height: 2;
}

.el-loading-spinner .path.path {
  stroke: #{vars.$stja-purpur};
  stroke-width: 6;
}

.el-dropdown-menu__link-item.el-dropdown-menu__link-item {
  padding: 0;
}
.el-dropdown-menu__link-item > a, .el-dropdown-menu__link-item > span {
  display: block;
  padding: 5px 16px;
  color: inherit;
  text-decoration: none;
}

.el-dropdown-menu__link-item.el-dropdown-menu__item--divided:before {
  margin: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  --header-bg-rgb: 255 255 255;
}
html.dark {
  --header-bg-rgb: 51 51 51;
}
body {
  --el-font-family: stja,"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-family: var(--el-font-family), sans-serif;
  word-spacing: 1px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

a {
  color: #{vars.$stja-mittelblau};
}
a.el-button {
  text-decoration: none;
}
h1, h2 {
  font-weight: bold;
}

.el-menu-item.has-link {
  padding: 0;
}
.MobileMenu,
.el-menu--horizontal,
.el-menu--popup {
  .el-menu-item.has-link > a,
  .el-menu-item.has-link > button {
    display: block;
    padding: 0 20px;
    text-decoration: none;
    height: 60px;
    line-height: 60px;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    color: inherit;
  }
}
.el-menu--popup .el-menu-item.has-link {
  padding: 0;
}
.el-menu--popup .el-menu-item.has-link > a,
.el-menu--popup .el-menu-item.has-link > button {
  display: block;
  width: 100%;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  text-decoration: none;
  text-align: left;
  background: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
}

.el-descriptions__body.el-descriptions__body {
  background-color: var(--el-fill-color-darker);
}

// WCAG fixes for element our element plus theme
@supports (color: oklch(from black l c h)) {
  // https://verou.me/blog/2024/contrast-color/
  .el-tag.el-tag--warning {
    --el-tag-text-color: oklch(from var(--el-tag-bg-color) clamp(0.3, (l / var(--l-threshold, 0.623) - 1) * -infinity, 1) 0 h);
  }
}
.el-tag.el-tag--dark.el-tag--warning {
  --el-tag-text-color: var(--el-color-black);
}
.el-button--warning {
  --el-button-text-color: var(--el-color-black);
  --el-button-hover-text-color: var(--el-color-black);
}

.Content.Content {
  min-height: calc(100vh - 122px);
}
.Content_centered.Content_centered {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Content_with-primary-actions {
  padding-bottom: 80px;
}

.Card {
  width: calc(100vw - 40px);
  max-width: 500px;
  padding: 1rem;
  background: rgb(var(--header-bg-rgb) / .75);
  border: 1px solid var(--el-border-color);
  border-radius: 3px;
}

.Layout__footer-visual {
  display: none;
}
@supports (position: fixed) {
  .Layout__footer-visual {
    position: fixed;
    z-index: -1;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .is-light {
      display: block;
    }
    img {
      display: none;
      min-height: 300px;
      max-height: 500px;
      width: auto;
      height: auto;
    }
    html.dark & {
      .is-light {
        display: none;
      }
      .is-dark {
        display: block;
      }
    }
  }
}

.AppTitle {
  margin-bottom: 10px;
}


.trans-fade-enter-active, .trans-fade-leave-active {
  transition: opacity .3s;
}
.trans-fade-enter, .trans-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.u-warning-text {
  color: #{vars.$color-warning};
}
.u-danger-text {
  color: #{vars.$color-danger};
}
.u-success-text {
  color: #{vars.$color-success};
}

.u-invisible {
  position: absolute;
  left: -9999em;
  top: -9999em;
  width: 1px;
  height: 1px;
  pointer-events: none;
  overflow: clip;
}

.u-flex-spacer {
  flex: 1;
}

.u-no-break {
  white-space: nowrap;
}

/*
Layout css
 */

.AppHeader {
  display: grid;
  grid-template-areas: "all";
  border-bottom: solid 1px var(--el-border-color);
  align-items: center;
  .AppHeader__left, .AppHeader__right, .AppHeader__center {
    display: flex;
  }
  .AppHeader__left {
    grid-area: all;
    justify-self: start;
  }
  .AppHeader__right {
    grid-area: all;
    justify-self: end;
  }
  .AppHeader__center {
    grid-area: all;
    justify-self: center;
  }
  .el-menu {
    border-bottom: none;
  }
}

.AppHeader__logo {
  line-height: 40px;
  align-self: flex-start;
  margin-top: 3px;
  display: flex;
  transition: opacity 0.2s linear;
  transition-delay: 0.5s;

  &.router-link-exact-active {
    .Logo__triangle {
      fill: #{vars.$stja-mittelblau};
    }
  }
}

.AppHeader__fill {
  flex: 1;
}

.AppHeader__hamburger-button {
  color: #000;

  &:focus,
  &:hover {
    color: #{vars.$stja-mittelblau};
  }
}

.AppHeader__hamburger {
  fill: currentColor;
}

.AppHeader__submenu {
  background: rgb(var(--header-bg-rgb) / 1);
}

// specificity needs to overwrite
// .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus
.AppHeader__logout.AppHeader__logout.AppHeader__logout {
  &:hover,
  &:focus {
    background-color: rgb(204, 204, 204);
  }
}

.AppHeader .el-menu-item .el-badge__content.is-fixed {
  top: 16px;
  right: 0;
  z-index: 20;
}
.AppHeader .el-submenu__title .el-badge__content.is-fixed {
  top: 16px;
  right: -18px;
}

@media print {
  .u-do-not-print {
    display: none !important;
  }
}
