@font-face {
  font-family: "stja";
  src: url("./fonts/stja_regular-webfont.woff2") format("woff2"),
    url("./fonts/stja_regular-webfont.woff") format("woff"),
    url("./fonts/stja_regular-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "stja";
  src: url("./fonts/stja_regular_italic-webfont.woff2") format("woff2"),
    url("./fonts/stja_regular_italic-webfont.woff") format("woff"),
    url("./fonts/stja_regular_italic-webfont.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "stja";
  src: url("./fonts/stja_bold-webfont.woff2") format("woff2"),
    url("./fonts/stja_bold-webfont.woff") format("woff"),
    url("./fonts/stja_bold-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "stja";
  src: url("./fonts/stja_bold_italic-webfont.woff2") format("woff2"),
    url("./fonts/stja_bold_italic-webfont.woff") format("woff"),
    url("./fonts/stja_bold_italic-webfont.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "stja";
  src: url("./fonts/stja_light-webfont.woff2") format("woff2"),
    url("./fonts/stja_light-webfont.woff") format("woff"),
    url("./fonts/stja_light-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "stja";
  src: url("./fonts/stja_light_italic-webfont.woff2") format("woff2"),
    url("./fonts/stja_light_italic-webfont.woff") format("woff"),
    url("./fonts/stja_light_italic-webfont.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

//@font-face {
//  font-family: 'kapass-icons';
//  src:
//  url('./fonts/kapass-icons.woff2') format('woff2'),
//  url('./fonts/kapass-icons.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//
//[class^="ka-icon-"]:before, [class*=" ka-icon-"]:before {
//  font-family: "kapass-icons";
//  font-style: normal;
//  font-weight: normal;
//  speak: never;
//
//  display: inline-block;
//  text-decoration: inherit;
//  width: 1em;
//  //margin-right: .2em;
//  text-align: center;
//  /* opacity: .8; */
//
//  /* For safety - reset parent styles, that can break glyph codes*/
//  font-variant: normal;
//  text-transform: none;
//
//  /* fix buttons height, for twitter bootstrap */
//  line-height: 1em;
//
//  /* Animation center compensation - margins should be symmetric */
//  /* remove if not needed */
//  //margin-left: .2em;
//
//  /* you can be more comfortable with increased icons size */
//  /* font-size: 120%; */
//
//  /* Font smoothing. That was taken from TWBS */
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//
//  /* Uncomment for 3D effect */
//  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
//}
//
//@import "./fonts/kapass-icons-codes.css";
