@use "vars";
@import "../../../node_modules/trix/dist/trix.css";

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  font-family: system, -apple-system, 'San Francisco', '.SFNSDisplay-Regular', 'Segoe UI', Segoe, 'Segoe WP', 'Helvetica Neue', helvetica, 'Lucida Grande', arial, sans-serif;
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment--preview {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

.attachment__action {
  display: inline-block;
  color: #{vars.$stja-mittelblau};
  margin: 0 0.25rem;
}
.attachment__zoom {
  cursor: zoom-in;
}

html.dark trix-toolbar .trix-button {
  filter: invert(90%);
}
